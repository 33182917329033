import React from "react";


const ManagePharmacies = () => {
  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <div className="h-full ml-14 mt-14 mb-10 md:ml-64">
        <div className="">
          <div className ="flex justify-center font-bold p-4">Pharmacies we are working with</div>
          <div className="w-full flex gap-3 p-4">
            <div className="relative flex w-full max-w-[16rem] max-h-[32rem] flex-col rounded-xl bg-gray-600  bg-clip-border shadow-lg">
              <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
                <img
                  src="https://images.unsplash.com/photo-1576602976047-174e57a47881?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8UGhhcm1hY3l8ZW58MHx8MHx8fDA%3D"
                  alt="ui/ux review check"
                />
                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60" />
              </div>
              <div className="p-6">
                <div className="mb-3 flex flex-col">
                    <h1 className="whitespace-nowrap mb-1">Name : Robot Pharmacy</h1>
                    <p className="">Location : Addis Ababa , Mexico</p>
                </div>
              </div>
            </div>
            <div className="relative flex w-full max-w-[16rem] max-h-[32rem] flex-col rounded-xl bg-gray-600  bg-clip-border shadow-lg">
              <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
                <img
                  src="https://media.istockphoto.com/id/659965216/photo/can-i-recommend-this.webp?b=1&s=170667a&w=0&k=20&c=b3j3WPWwAzLls4I7nLQCBlrGcITpPp4KyWLvLh0inYE="
                  alt="ui/ux review check"
                />
                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60" />
              </div>
              <div className="p-6">
                <div className="mb-3 flex flex-col">
                    <h1 className="whitespace-nowrap mb-1">Name : Robot Pharmacy</h1>
                    <p className="">Location : Addis Ababa , Mexico</p>
                </div>
              </div>
            </div>
            <div className="relative flex w-full max-w-[16rem] max-h-[32rem] flex-col rounded-xl bg-gray-600  bg-clip-border shadow-lg">
              <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
                <img
                  src="https://media.istockphoto.com/id/1325914490/photo/modern-pharmacy-drugstore-with-shelves-full-of-packages-full-of-modern-medicine-drugs-vitamin.webp?b=1&s=170667a&w=0&k=20&c=ZRpCHwo82BxupO95AWRl5tzEXQr_r4LwMggBLYQinmo="
                  alt="ui/ux review check"
                />
                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60" />
              </div>
              <div className="p-6">
                <div className="mb-3 flex flex-col">
                    <h1 className="whitespace-nowrap mb-1">Name : Robot Pharmacy</h1>
                    <p className="">Location : Addis Ababa , Mexico</p>
                </div>
              </div>
            </div>
            <div className="relative flex w-full max-w-[16rem] max-h-[32rem] flex-col rounded-xl bg-gray-600  bg-clip-border shadow-lg">
              <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
                <img
                  src="https://media.istockphoto.com/id/1322693212/photo/cropped-shot-of-shelves-stocked-with-various-medicinal-products-in-a-pharmacy.webp?b=1&s=170667a&w=0&k=20&c=c9OZNpWSnVICybJFpvY3UU0fU_b9deP2jtTCkRDUyPs="
                  alt="ui/ux review check"
                />
                <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60" />
              </div>
              <div className="p-6">
                <div className="mb-3 flex flex-col">
                    <h1 className="whitespace-nowrap mb-1">Name : Robot Pharmacy</h1>
                    <p className="">Location : Addis Ababa , Mexico</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePharmacies;
